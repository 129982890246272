import React from "react";
import { inject, observer } from "mobx-react";
import { Accordion, Card, Modal, Button } from "react-bootstrap";
import {
  BiChevronDown,
  BiChevronRight,
  BiFile,
  BiMessageSquareError,
  BiPrinter,
} from "react-icons/bi";
import { PaymentMethodCCForm } from "../components/PaymentMethodCCForm";
import { PaymentMethodECheck } from "../components/PaymentMethodECheck";
import { PaymentMethodsAccepted } from "../components/PaymentMethodsAccepted";
import { IMaskInput } from "react-imask";
import { toJS } from "mobx";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { PaymentPageLogo } from "../components/PaymentPageLogo";
import NumberFormat from "react-number-format";
import achIcon from "../assets/images/ach.svg";
import cardIcon from "../assets/images/card.svg";
import PhoneInput from "../components/PhoneInput";
import { AutopayForm } from "../components/AutopayForm";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Captcha } from "./Captcha";
import * as Sentry from "@sentry/react";

@inject("store")
@observer
class PayLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleCategoryTextChangeMask =
      this.handleCategoryTextChangeMask.bind(this);
    this.renderSortBlocks = this.renderSortBlocks.bind(this);
    this.goToReceipt = this.goToReceipt.bind(this);
    this.handleCategoryQtySubstract =
      this.handleCategoryQtySubstract.bind(this);
    this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAutopayEndDate = this.handleAutopayEndDate.bind(this);
    this.handleTextPayorFieldsChange =
      this.handleTextPayorFieldsChange.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.handleCategoryOptionalPay = this.handleCategoryOptionalPay.bind(this);
    this.handleAutopay = this.handleAutopay.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.updateCredentialsPermissionsUI =
      this.updateCredentialsPermissionsUI.bind(this);
    this.focus = this.focus.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.reviewPayment = this.reviewPayment.bind(this);
    this.handleTextChangeValidationCode =
      this.handleTextChangeValidationCode.bind(this);
    this.viewInvoiceDetail = this.viewInvoiceDetail.bind(this);
    this.printPage = this.printPage.bind(this);
    this.handleItemQtySubstract = this.handleItemQtySubstract.bind(this);
    this.handleItemQtyAdd = this.handleItemQtyAdd.bind(this);
    this.handleItemQtyInput = this.handleItemQtyInput.bind(this);
    this.setPhoneError = this.setPhoneError.bind(this);

    this.state = {
      infoModalIsOpen: true,
      phoneError: false,
      infoMessageInModal: "",
      infoModalReload: false,
      inputQtyError: false,
      invoiceDetailsOpen: false,
      numberFailedAttempts: 0,
    };
  }

  closeInfoModal() {
    this.setState({ infoModalIsOpen: true });

    if (this.state.infoModalReload === true) {
      window.location.reload();
    }
  }

  closeConfirmModal(info) {
    this.setState({ confirmModalIsOpen: false });
  }

  openConfirmModal(info) {
    this.setState({ confirmModalIsOpen: true });
  }

  viewInvoiceDetail() {
    this.setState({ invoiceDetailsOpen: !this.state.invoiceDetailsOpen });
  }

  printPage() {
    window.print();
  }

  openInfoModal(info, reload) {
    this.setState({
      infoModalIsOpen: false,
      infoMessageInModal: info,
      infoModalReload: reload === true ? true : false,
    });
  }

  goToReceipt() {
    this.props.history.push(
      "/paymentlink/receipt/" + this.props.match.params.tokenid
    );
  }

  handleAutopay(event) {
    event.preventDefault();
    this.props.store.handleAutopay();
    this.updateCredentialsPermissionsUI();
  }

  focus(e) {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  reviewPayment() {
    this.props.store.setIsClickedPay(true);
    this.validateFields();
    if (this.props.store.getPaymentPageErrors.credentialsMaxMinTicketError) {
      this.openInfoModal(
        "Sorry, the amount to be paid is greater or less than what is allowed."
      );
      return;
    }

    if (this.props.store.totalAmount.netAmount === 0) {
      this.openInfoModal("Sorry, the amount must be greater than $0.00");
      return;
    } else {
      if (!this.props.store.hasPaymentPageErrors()) {
        if (!this.props.store.isAllowedPaymentMethod()) {
          this.openInfoModal(
            "Sorry, the selected payment method is not allowed."
          );
          return;
        }
        this.openConfirmModal();
      }
    }
  }

  makePayment() {
    this.props.store.setIsClickedPay(true);
    if (
      this.props.store.getValidationCode() &&
      this.validateFieldValidationCode()
    ) {
      return;
    }
    
    let subdomain = this.props.store.getPaymentPageSubdomain;
    let entry = this.props.match.params.entry;
    this.props.store
      .makePayment(entry)
      .then((response) => {
        this.goToReceipt();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((reason) => {
        Sentry.captureException(reason);
        this.handleTextChangeValidationCode("");
        try {
          if (reason?.response?.data?.responseCode === 9006) {
            const numberFailedAttempts = this.state.numberFailedAttempts + 1;
            if (numberFailedAttempts === 3) {
              this.closeConfirmModal();
              this.openInfoModal(
                "CAPTCHA code was entered incorrectly. Please try again.",
                true
              );
              return;
            }
            toast.error("Invalid Captcha. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "toast-error-container",
            });
            this.props.store.handleRefreshValidationCode(subdomain, entry);
            this.setState({ numberFailedAttempts });
          } else {
            this.closeConfirmModal();
            if (JSON.parse(reason.request.response).responseText) {
              this.openInfoModal(
                JSON.parse(reason.request.response).responseText,
                true
              );
            } else {
              this.openInfoModal(
                "Sorry, we have a problem processing your request. Try again later.",
                true
              );
            }
          }
        } catch (e) {
          this.closeConfirmModal();
          this.openInfoModal(
            "Sorry, we have a problem processing your request. Try again later.",
            true
          );
        }
      });
  }

  validateFieldValidationCode() {
    let validators = this.props.store.validators;
    let validationCode = this.props.store.getValidationCodeInput;
    if (
      validators.isEmpty(validationCode) ||
      validators.isMaxLength(250, validationCode)
    ) {
      this.props.store.setPaymentPageError("validationCode", true);
      return true;
    } else {
      this.props.store.setPaymentPageError("validationCode", false);
      return false;
    }
  }

  validateFields() {
    var paymentPage = this.props.store.paymentPage;
    var validators = this.props.store.validators;
    var reactObj = this;

    // validating autopay fields
    if (this.props.store.isAutopay) {
      if (
        validators.isEmpty(paymentPage.autopay.startDate) ||
        validators.isMaxLength(250, paymentPage.autopay.startDate)
      ) {
        this.props.store.setPaymentPageError("autopayStartDateError", true);
      } else {
        this.props.store.setPaymentPageError("autopayStartDateError", false);
      }

      if (
        validators.isEmpty(paymentPage.autopay.frequencySelected) ||
        validators.isMaxLength(250, paymentPage.autopay.frequencySelected)
      ) {
        this.props.store.setPaymentPageError("autopayFrequencyError", true);
      } else {
        this.props.store.setPaymentPageError("autopayFrequencyError", false);
      }

      if (paymentPage.autopay.frequencySelected !== "onetime") {
        if (
          validators.isEmpty(paymentPage.autopay.finishSelected) ||
          validators.isMaxLength(250, paymentPage.autopay.finishSelected)
        ) {
          this.props.store.setPaymentPageError("autopayFinishError", true);
        } else {
          this.props.store.setPaymentPageError("autopayFinishError", false);
        }
      } else {
        this.props.store.setPaymentPageError("autopayFinishError", false);
      }
    } else {
      this.props.store.setPaymentPageError("autopayStartDateError", false);
      this.props.store.setPaymentPageError("autopayFrequencyError", false);
      this.props.store.setPaymentPageError("autopayFinishError", false);
    }

    // validating notes
    if (paymentPage.notes.enabled) {
      if (validators.isMaxLength(250, paymentPage.notes.value)) {
        this.props.store.setPaymentPageError("notesError", true);
      } else {
        this.props.store.setPaymentPageError("notesError", false);
      }
    }

    // validating payor form
    if (paymentPage.payor.enabled) {
      var payerFieldsSort = Object.entries(toJS(this.props.store.payerFields));
      payerFieldsSort.forEach(function (item, index) {
        if (item[1].required && item[1].display) {
          if (
            validators.isEmpty(item[1].value) ||
            validators.isMaxLength(1000, item[1].value) ||
            (item[1].validation !== "phone" &&
              validators.stringValidator(item[1].validation, item[1].value))
          ) {
            reactObj.props.store.setPaymentPageError([item[1].name], true);
          } else {
            reactObj.props.store.setPaymentPageError([item[1].name], false);
          }
        } else if (!item[1].required && item[1].display) {
          if (
            item[1].value &&
            (validators.isMaxLength(1000, item[1].value) ||
              (item[1].validation !== "phone" &&
                validators.stringValidator(item[1].validation, item[1].value)))
          ) {
            reactObj.props.store.setPaymentPageError([item[1].name], true);
          } else {
            reactObj.props.store.setPaymentPageError([item[1].name], false);
          }
        }
      });
    }

    // validating ach form
    if (this.props.store.getPaymentMethod === "ach") {
      this.props.store.setPaymentPageError(
        "paymentMethodsCardNumberError",
        false
      );
      this.props.store.setPaymentPageError(
        "paymentMethodsCardExpirationDateError",
        false
      );
      this.props.store.setPaymentPageError("paymentMethodsCardCvvError", false);
      this.props.store.setPaymentPageError(
        "paymentMethodsCardZipcodeError",
        false
      );
      this.props.store.setPaymentPageError(
        "paymentMethodsCardHolderNameError",
        false
      );

      if (
        validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) ||
        validators.isMaxLength(
          250,
          paymentPage.paymentMethods.achAccountHolderName
        )
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountHolderNameError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountHolderNameError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.achAccountType) ||
        validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType)
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountTypeError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountTypeError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.achRouting) ||
        !this.props.store.isValidRouting(
          paymentPage.paymentMethods.achRouting
        ) ||
        validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) ||
        validators.stringValidator(
          "routing",
          paymentPage.paymentMethods.achRouting
        )
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchRoutingError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchRoutingError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.achAccount) ||
        validators.stringValidator(
          "numbers",
          paymentPage.paymentMethods.achAccount
        ) ||
        validators.isMinLength(4, paymentPage.paymentMethods.achAccount) ||
        validators.isMaxLength(17, paymentPage.paymentMethods.achAccount)
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsAchAccountError",
          false
        );
      }
    } else if (this.props.store.getPaymentMethod === "card") {
      this.props.store.setPaymentPageError(
        "paymentMethodsAchAccountHolderNameError",
        false
      );
      this.props.store.setPaymentPageError(
        "paymentMethodsAchAccountTypeError",
        false
      );
      this.props.store.setPaymentPageError(
        "paymentMethodsAchRoutingError",
        false
      );
      this.props.store.setPaymentPageError(
        "paymentMethodsAchAccountError",
        false
      );

      if (
        validators.isEmpty(paymentPage.paymentMethods.cardNumber) ||
        validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) ||
        validators.stringValidator(
          "card",
          paymentPage.paymentMethods.cardNumber
        ) ||
        !this.props.store.luhnCheck(paymentPage.paymentMethods.cardNumber)
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardNumberError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardNumberError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) ||
        validators.isMaxLength(
          4,
          paymentPage.paymentMethods.cardExpirationDate
        ) ||
        validators.stringValidator(
          "exp",
          paymentPage.paymentMethods.cardExpirationDate
        )
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardExpirationDateError",
          true
        );
      } else {
        let expDateYear =
          paymentPage.paymentMethods.cardExpirationDate.substr(-2);
        let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(
          0,
          2
        );
        let currentYear = new Date().getFullYear().toString().substr(-2);
        let currentMonth = new Date().getMonth().toString();

        if (parseInt(expDateYear) < parseInt(currentYear)) {
          this.props.store.setPaymentPageError(
            "paymentMethodsCardExpirationDateError",
            true
          );
        } else {
          this.props.store.setPaymentPageError(
            "paymentMethodsCardExpirationDateError",
            false
          );
        }

        if (
          (parseInt(expDateYear) === parseInt(currentYear) &&
            parseInt(expDateMonth) < parseInt(currentMonth) + 1) ||
          parseInt(expDateMonth) > 12
        ) {
          this.props.store.setPaymentPageError(
            "paymentMethodsCardExpirationDateError",
            true
          );
        }
      }

      let ccType = this.props.store.creditCardType(
        paymentPage.paymentMethods.cardNumber
      );
      if (
        validators.isEmpty(paymentPage.paymentMethods.cardCvv) ||
        (ccType === "american-express"
          ? validators.stringValidator(
              "cvvamex",
              paymentPage.paymentMethods.cardCvv
            )
          : validators.stringValidator(
              "cvv",
              paymentPage.paymentMethods.cardCvv
            ))
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardCvvError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardCvvError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.cardZipcode) ||
        validators.isMaxLength(7, paymentPage.paymentMethods.cardZipcode) ||
        validators.stringValidator(
          "zipcode",
          paymentPage.paymentMethods.cardZipcode
        )
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardZipcodeError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardZipcodeError",
          false
        );
      }

      if (
        validators.isEmpty(paymentPage.paymentMethods.cardHolderName) ||
        validators.stringValidator(
          "alpha",
          paymentPage.paymentMethods.cardHolderName
        )
      ) {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardHolderNameError",
          true
        );
      } else {
        this.props.store.setPaymentPageError(
          "paymentMethodsCardHolderNameError",
          false
        );
      }
    }

    // validating payment categories
    if (paymentPage.amount.enabled) {
      var categoriesConfig = Object.entries(
        toJS(this.props.store.amountCategories)
      );
      categoriesConfig.forEach(function (item, index) {
        if (
          !item[1].optionalPay &&
          (validators.isEmpty(item[1].value) ||
            parseFloat(item[1].value) === 0 ||
            isNaN(item[1].value))
        ) {
          reactObj.props.store.setPaymentPageError([item[1].name], true);
        } else {
          reactObj.props.store.setPaymentPageError([item[1].name], false);
        }
      });
    }

    this.props.store.validateCredentialsMaxMinTicketError();

    setTimeout(function () {
      try {
        let inputs = document.getElementsByClassName("input-error");
        if (inputs[0]) {
          let objRect = inputs[0].getBoundingClientRect();
          window.scrollTo({
            top: parseInt(objRect.top + window.scrollY - 120),
            behavior: "smooth",
          });
          inputs[0].focus();
        }
      } catch (e) {
        console.log(e);
      }
    }, 200);
  }

  setPhoneError(error) {
    this.setState({ phoneError: error });
  }
  renderFields() {
    var reactObject = this;
    var loopData = [];
    var payerFieldsSort = this.sortFields(
      Object.entries(toJS(this.props.store.payerFields))
    );

    payerFieldsSort.forEach(function (item, index) {
      loopData.push(
        item[1].display && (
          <div key={"keyPayer" + item[0]} className={"col-sm-" + item[1].width}>
            {item[1].validation === "phone" && (
              <PhoneInput
                iReadonly={item[1].fixedInjected === true ? true : false}
                iRequired={item[1].required}
                iValue={item[1].value}
                iSetError={reactObject.setPhoneError}
                iCustomClass="inner-addon"
                iTitle={item[1].label}
                iMask="phone"
                iOnChange={(mask, value) =>
                  reactObject.handleTextPayorFieldsChange(item[0], value)
                }
                iName={"payorFieldInput" + item[0]}
                full
              />
            )}
            {item[1].validation !== "phone" && (
              <>
                <div className="form-floating mb-3">
                  <IMaskInput
                    mask={reactObject.props.store.maskValidator(
                      item[1].validation
                    )}
                    name={"payorFieldInput" + item[0]}
                    value={item[1].value}
                    unmask={true}
                    onAccept={(value, mask) =>
                      reactObject.handleTextPayorFieldsChange(item[0], value)
                    }
                    placeholder={item[1].label}
                    className={
                      reactObject.props.store.getPaymentPageErrors[
                        item[1].name
                      ] &&
                      reactObject.props.store.getPaymentPageErrors[
                        item[1].name
                      ] === true
                        ? "form-control input-error"
                        : "form-control"
                    }
                    autoComplete="off"
                    id={item[1].name}
                    readOnly={item[1].fixedInjected === true ? true : false}
                  />
                  <label htmlFor={"payorFieldInput" + item[0]}>
                    {item[1].label}
                  </label>
                </div>
              </>
            )}
          </div>
        )
      );
    });
    return loopData;
  }

  handleCategoryOptionalPay(event) {
    this.props.store.handleCategoryOptionalPay(event);
  }

  setPaymentMethod(method, activeKey) {
    this.props.store.setPaymentMethod(method);
    if (activeKey !== null) {
      this.props.store.setDefaultPaymentMethodActiveKey(activeKey);
    }
  }

  handleAutopayEndDate(event) {
    this.props.store.handleAutopayEndDate(event);
  }

  handleCategoryTextChangeMask(field, key, value) {
    this.props.store.handleCategoryTextChangeMask(field, key, value);
  }

  handleTextChange(block, key, event) {
    this.props.store.handleTextChange(block, key, event);
  }

  handleTextPayorFieldsChange(key, value) {
    this.props.store.handleTextPayorFieldsChange(key, value);
  }

  handleTextChangeValidationCode(validationCode) {
    this.props.store.handleTextChangeValidationCodeInput(validationCode);
  }

  handleCategoryQtySubstract(event) {
    this.props.store.handleCategoryQtySubstract(event);
  }

  handleCategoryQtyAdd(event) {
    this.props.store.handleCategoryQtyAdd(event);
  }

  handleItemQtySubstract(event) {
    this.props.store.handleItemQtySubstract(event);
  }

  handleItemQtyAdd(event) {
    this.props.store.handleItemQtyAdd(event);
  }

  handleItemQtyInput(event) {
    if (!this.state.inputQtyError) {
      this.props.store.handleItemQtyInput(event);
    }
  }

  renderAmountCategories() {
    var loopData = [];
    var categoriesConfigSort = this.sortFields(
      Object.entries(toJS(this.props.store.amountCategories))
    );
    var categoriesCount = categoriesConfigSort.length;
    var reactObject = this;
    categoriesConfigSort.forEach(function (item, index) {
      if (categoriesCount > 1) {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
            style={{ position: "relative" }}
          >
            <div className="form-floating form-floating-money">
              <NumberFormat
                onFocus={(e) => reactObject.focus(e)}
                thousandsGroupStyle="thousand"
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                value={item[1].value}
                placeholder={item[1].label}
                className={
                  reactObject.props.store.getPaymentPageErrors[item[1].name]
                    ? "form-control input-money input-error"
                    : "form-control input-money"
                }
                onValueChange={(values) =>
                  reactObject.handleCategoryTextChangeMask(
                    item[0],
                    "value",
                    values.value
                  )
                }
                readOnly={
                  item[1].type === "fixed" || item[1].type === "quantity"
                    ? true
                    : false
                }
              />
              <label>{item[1].label}</label>
            </div>
            {item[1].optionalPay && (
              <div className="icheck-primary optional-pay">
                <input
                  type="checkbox"
                  id={"amountCategoryOptionalPay" + item[0]}
                  onChange={(e) => reactObject.handleCategoryOptionalPay(e)}
                />
                <label htmlFor={"amountCategoryOptionalPay" + item[0]}></label>
              </div>
            )}

            {item[1].type === "quantity" && (
              <div
                className={
                  item[1].optionalPay
                    ? "input-group input-group-qty input-group-qty-mr"
                    : "input-group input-group-qty"
                }
              >
                <button
                  id={"amountCategoryQtySubstract" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtySubstract(e)}
                >
                  -
                </button>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Example text with button addon"
                  readOnly
                  value={item[1].quantity}
                />
                <button
                  id={"amountCategoryQtyAdd" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtyAdd(e)}
                >
                  +
                </button>
              </div>
            )}

            {item[1].description && item[1].showDescription ? (
              <p className="small-grey-m0 mt-1">{item[1].description}</p>
            ) : (
              ""
            )}
          </li>
        );
      } else {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
          >
            <NumberFormat
              onFocus={(e) => reactObject.focus(e)}
              thousandsGroupStyle="thousand"
              prefix="$"
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              value={item[1].value}
              placeholder={"$"}
              className="big-amount"
              onValueChange={(values) =>
                reactObject.handleCategoryTextChangeMask(
                  item[0],
                  "value",
                  values.value
                )
              }
              readOnly={
                item[1].type === "fixed" || item[1].type === "quantity"
                  ? true
                  : false
              }
            />
          </li>
        );
      }
    });

    return loopData;
  }

  sortFields(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j][1].order > inputArr[j + 1][1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  sortBlocks(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j].order > inputArr[j + 1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  componentDidMount() {
    this.props.store.setCurrentPage("payment-page");
    let tokenid = this.props.match.params.tokenid;
    if (tokenid) {
      this.props.store
        .getPaymentPageLinkFromApi(tokenid)
        .then((res) => {
          this.updateCredentialsPermissionsUI();
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            this.props.history.push("/paymentlink/paid");
          } else {
            this.props.history.push("/");
          }
        });
    } else {
      this.props.history.push("/");
    }

    this.setState({ infoModalReload: false });
  }

  datainjectedQueryString(datainjectedQueryString) {
    let queryStringParams = new URLSearchParams(datainjectedQueryString);
    try {
      if (queryStringParams.get("amount")) {
        this.props.store.setPaymentPageCategories([
          {
            description: "",
            label: "",
            name: String(Date.now()),
            optionalPay: false,
            order: 1,
            quantity: 1,
            showDescription: false,
            type: "fixed",
            value: String(queryStringParams.get("amount")),
          },
        ]);
      }

      if (parseInt(queryStringParams.get("autopay")) === 0) {
        this.props.store.setAutopay(false);
        this.props.store.setSwitchAutopay(false);
      }

      if (queryStringParams.entries()) {
        let store = this.props.store;
        for (var pair of queryStringParams.entries()) {
          if (pair[1]) {
            store.setPayorValue(pair[0], pair[1], true);
          }
        }
      }

      if (parseInt(queryStringParams.get("card")) === 0) {
        if (this.props.store.hasECheck) {
          this.props.store.setCardsActive(false);
        }
      }

      if (parseInt(queryStringParams.get("ach")) === 0) {
        if (this.props.store.hasCards) {
          this.props.store.setEcheckActive(false);
        }
      }
    } catch (e) {
      console.log(e);
      console.log("QueryString parameter is malformed");
    }
  }

  dataInjectedBase64Json(dataInjectedBase64) {
    let jsonDataInject = {};

    dataInjectedBase64 = dataInjectedBase64.replaceAll(".", "+");
    dataInjectedBase64 = dataInjectedBase64.replaceAll("_", "/");

    try {
      jsonDataInject = JSON.parse(
        window.atob(dataInjectedBase64).toString(),
        1
      );

      if (jsonDataInject.amount) {
        this.props.store.setPaymentPageCategories([
          {
            description: jsonDataInject.description
              ? String(jsonDataInject.description)
              : "",
            label: "",
            name: String(Date.now()),
            optionalPay: false,
            order: 1,
            quantity: 1,
            showDescription: false,
            type: jsonDataInject.fixedAmount === 1 ? "fixed" : "customer",
            value: String(jsonDataInject.amount),
          },
        ]);
      }

      if (parseInt(jsonDataInject.autopay) === 0) {
        this.props.store.setAutopay(false);
        this.props.store.setSwitchAutopay(false);
      }

      if (jsonDataInject.payor) {
        let jsonDataInjectPayor = jsonDataInject.payor;
        let store = this.props.store;
        for (let key in jsonDataInjectPayor) {
          store.setPayorValue(
            key,
            jsonDataInjectPayor[key].value,
            jsonDataInjectPayor[key].fixed === 1 ? true : false
          );
        }
      }

      if (parseInt(jsonDataInject.card) === 0) {
        if (this.props.store.hasECheck) {
          this.props.store.setCardsActive(false);
        }
      }

      if (parseInt(jsonDataInject.ach) === 0) {
        if (this.props.store.hasCards) {
          this.props.store.setEcheckActive(false);
        }
      }
    } catch (e) {
      console.log(e);
      console.log("JSON parameter is malformed");
    }
  }

  updateCredentialsPermissionsUI() {
    let credentialsPermissions = this.props.store.getCredentialsPermissions;
    if (
      (credentialsPermissions.card.onetime && !this.props.store.isAutopay) ||
      (credentialsPermissions.card.recurring && this.props.store.isAutopay)
    ) {
      this.props.store.setDefaultPaymentMethodActiveKey("0");
      this.setPaymentMethod("card", "0");
    }

    if (
      (credentialsPermissions.ach.onetime && !this.props.store.isAutopay) ||
      (credentialsPermissions.ach.recurring && this.props.store.isAutopay)
    ) {
      this.props.store.setDefaultPaymentMethodActiveKey("1");
      this.setPaymentMethod("ach", "1");
    }

    if (
      credentialsPermissions.ach.recurring ||
      credentialsPermissions.card.recurring ||
      credentialsPermissions.wallet.recurring ||
      credentialsPermissions.cloud.recurring
    ) {
      this.props.store.setAutopay(this.props.store.isAutopay);
      //this.props.store.setAutopay(false);
    } else {
      this.props.store.setAutopay(false);
    }
  }

  renderSortBlocks() {
    let credentialsPermissions = this.props.store.getCredentialsPermissions;

    const btnTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const linkTheme = {
      color: this.props.store.paymentPageSettings.color,
    };

    const bgTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let nextTwoDays = new Date();
    nextTwoDays.setDate(nextTwoDays.getDate() + 2);
    if (this.props.store.paymentPage.autopay.startDate) {
      nextTwoDays = new Date(this.props.store.paymentPage.autopay.startDate);
      nextTwoDays.setDate(nextTwoDays.getDate() + 1);
    }

    const years = [];
    const yearsEndDate = [];
    let year = new Date().getFullYear();
    for (let i = year; i <= year + 2; i++) {
      years.push(i);
    }
    for (let i = year; i <= year + 20; i++) {
      yearsEndDate.push(i);
    }

    var blocksUI = [
      {
        elements: (
          <>
            {this.props.store.paymentPage.logo.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blocklogo"
                style={{ paddingBottom: 0 }}
              >
                <div className="text-center">
                  <PaymentPageLogo />
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.logo.order
          ? this.props.store.paymentPage.logo.order
          : 0,
      },

      {
        elements: (
          <>
            {" "}
            {this.props.store.paymentPage.page.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blockpage"
                style={{ paddingTop: 0 }}
              >
                <div className="row">
                  <div className="col-md-8 offset-md-2 progress-steps mb-3">
                    <div className="row">
                      <div style={bgTheme} className="col active">
                        &nbsp;
                      </div>
                      <div className="col">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="card-title text-center">
                      {this.props.store.paymentPage.page.header
                        ? this.props.store.paymentPage.page.header
                        : ""}
                    </h3>
                    <p
                      className="small small-grey text-center m0"
                      dangerouslySetInnerHTML={{
                        __html: this.props.store.paymentPage.page.description
                          ? this.props.store.paymentPage.page.description
                          : "",
                      }}
                    ></p>
                  </div>
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.page.order
          ? this.props.store.paymentPage.page.order
          : 0,
      },

      {
        elements: (
          <li className="ui-state-default dragable-panel" id="blockamount">
            <div className="payment-categories mb-1">
              <ul className="sorted" ref={this.sortableCategories}>
                {this.renderAmountCategories()}
              </ul>
            </div>
            {this.props.store.switchAutopay && (
              <>
                {this.props.store.isAutopay &&
                  (credentialsPermissions.ach.onetime ||
                    credentialsPermissions.card.onetime ||
                    credentialsPermissions.wallet.onetime ||
                    credentialsPermissions.cloud.onetime) && (
                    <p className="text-center">
                      <a
                        style={linkTheme}
                        href="/"
                        onClick={(e) => this.handleAutopay(e)}
                      >
                        Click here
                      </a>{" "}
                      to make One-Time Payment
                    </p>
                  )}
                {!this.props.store.isAutopay &&
                  (credentialsPermissions.ach.recurring ||
                    credentialsPermissions.card.recurring ||
                    credentialsPermissions.wallet.recurring ||
                    credentialsPermissions.cloud.recurring) && (
                    <p className="text-center">
                      <a
                        style={linkTheme}
                        href="/"
                        onClick={(e) => this.handleAutopay(e)}
                      >
                        Click here
                      </a>{" "}
                      to make an Autopay
                    </p>
                  )}
              </>
            )}
          </li>
        ),
        order: this.props.store.paymentPage.amount.order
          ? this.props.store.paymentPage.amount.order
          : 0,
      },

      {
        elements: (
          <>{this.props.store.paymentPage.autopay.enabled && <AutopayForm />}</>
        ),
        order: this.props.store.paymentPage.autopay.order
          ? this.props.store.paymentPage.autopay.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.payor.enabled && (
              <li className="ui-state-default dragable-panel" id="blockpayer">
                <h6 className="sub-header mb-3">
                  {this.props.store.paymentPage.payor.header
                    ? this.props.store.paymentPage.payor.header
                    : ""}
                </h6>
                <div className="row">{this.renderFields()}</div>
              </li>
            )}{" "}
          </>
        ),
        order: this.props.store.paymentPage.payor.order,
      },

      {
        elements: (
          <>
            {" "}
            {this.props.store.paymentPage.invoices &&
              this.props.store.paymentPage.invoices.enabled && (
                <li
                  className="ui-state-default dragable-panel"
                  id="blockinvoices"
                >
                  <div className="small mb-2">
                    {this.props.store.paymentPage.invoices.invoiceList.map(
                      (invoice, i) => (
                        <div key={i}>
                          <div className="row mb-2">
                            <div className="offset-sm-2 col-sm-4 mb-2 text-center">
                              <b>Invoice Number</b>
                              <br />
                              {invoice.invoiceNumber}
                            </div>
                            <div className="col-sm-4 mb-2 text-center">
                              <b>Due Date</b>
                              <br />
                              {this.props.store.stringDateFormat(
                                invoice.invoiceDueDate
                              )}
                            </div>
                          </div>

                          <div className="text-center">
                            <span
                              onClick={(e) => this.viewInvoiceDetail(e)}
                              style={linkTheme}
                              className="cursor-pointer"
                            >
                              {
                                this.props.store.paymentPage.invoices
                                  .viewInvoiceDetails.label
                              }{" "}
                              {this.state.invoiceDetailsOpen === false ? (
                                <BiChevronRight style={{ fontSize: "18px" }} />
                              ) : (
                                <BiChevronDown style={{ fontSize: "18px" }} />
                              )}
                            </span>
                          </div>
                          {this.state.invoiceDetailsOpen === true && (
                            <div>
                              <div className="mb-3 text-right">
                                <BiFile
                                  onClick={(e) =>
                                    this.props.store.downloadInvoice(e)
                                  }
                                  className="cursor-pointer mr-1"
                                  style={{ fontSize: "16px" }}
                                />
                                <BiPrinter
                                  onClick={(e) => this.printPage(e)}
                                  className="cursor-pointer"
                                  style={{ fontSize: "16px" }}
                                />
                              </div>
                              <div className="row mb-2">
                                <div className="col-3">
                                  <b>Product Service</b>
                                </div>
                                <div className="col-3 text-right">
                                  <b>Unit Price</b>
                                </div>
                                <div className="col-3 text-right">
                                  <b>Qty</b>
                                </div>
                                <div className="col-3 text-right">
                                  <b>Amount</b>
                                </div>
                              </div>

                              {invoice.items.map((item, x) => (
                                <div
                                  key={x}
                                  className="row top-border-grey-v2"
                                  style={{ fontSize: "12px" }}
                                >
                                  <div
                                    className="col-3"
                                    style={{ marginTop: "2px" }}
                                  >
                                    {item.itemProductName
                                      ? item.itemProductName
                                      : "-"}
                                  </div>
                                  <div
                                    className="col-3 text-right"
                                    style={{ marginTop: "2px" }}
                                  >
                                    $
                                    {item.itemCost
                                      ? this.props.store.numberWithCommas(
                                          parseFloat(item.itemCost).toFixed(
                                            this.props.store.formatDecimalDynamics(
                                              item.itemCost
                                            )
                                          )
                                        )
                                      : "0.00"}
                                  </div>
                                  <div
                                    className="col-3 text-right"
                                    style={{ marginTop: "2px" }}
                                  >
                                    {item.itemQty ? item.itemQty : "1"}
                                  </div>
                                  <div
                                    className="col-3 text-right"
                                    style={{ marginTop: "2px" }}
                                  >
                                    $
                                    {item.itemTotalAmount
                                      ? this.props.store.numberWithCommas(
                                          parseFloat(
                                            item.itemTotalAmount
                                          ).toFixed(
                                            this.props.store.formatDecimalDynamics(
                                              item.itemTotalAmount
                                            )
                                          )
                                        )
                                      : "0.00"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </li>
              )}{" "}
          </>
        ),
        order:
          this.props.store.paymentPage.invoices &&
          this.props.store.paymentPage.invoices.order
            ? this.props.store.paymentPage.invoices.order
            : 0,
      },

      {
        elements: (
          <li
            className="ui-state-default dragable-panel"
            id="blockpaymentMethods"
          >
            <h6 className="sub-header mb-3">
              {this.props.store.paymentPage.paymentMethods.header
                ? this.props.store.paymentPage.paymentMethods.header
                : ""}
            </h6>
            {!credentialsPermissions.ach.onetime &&
              !credentialsPermissions.ach.recurring &&
              !credentialsPermissions.card.onetime &&
              !credentialsPermissions.card.recurring && (
                <div style={{ margin: "50px 0 50px 0" }}>
                  <div className="alert alert-warning small">
                    There are no services activated for this Paypoint. Contact
                    your administrator to enable it.
                  </div>
                </div>
              )}
            <Accordion
              activeKey={this.props.store.defaultPaymentMethodActiveKey}
            >
              {this.props.store.hasECheck &&
                ((credentialsPermissions.ach.onetime &&
                  !this.props.store.isAutopay) ||
                  (credentialsPermissions.ach.recurring &&
                    this.props.store.isAutopay)) && (
                  <Card className="card card-in mb-3 card-hover">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="card-body"
                      eventKey="1"
                      onClick={(e) => this.setPaymentMethod("ach", "1")}
                    >
                      <div className="row">
                        <div className="col-2 text-center">
                          <img
                            alt="ACH"
                            style={{ width: "44px" }}
                            className="grey-icon-v2"
                            src={achIcon}
                          />
                        </div>
                        <div className="col-10">
                          Bank account / E-Check
                          <p className="small small-grey-m0">
                            Pay from your Bank Account
                          </p>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <PaymentMethodECheck history={this.props.history} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}

              {this.props.store.hasCards &&
                ((credentialsPermissions.card.onetime &&
                  !this.props.store.isAutopay) ||
                  (credentialsPermissions.card.recurring &&
                    this.props.store.isAutopay)) && (
                  <Card className="card card-in mb-3 card-hover">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="card-body"
                      eventKey="0"
                      onClick={(e) => this.setPaymentMethod("card", "0")}
                    >
                      <div className="row">
                        <div className="col-2 text-center">
                          <img
                            alt="Card"
                            style={{ width: "46px" }}
                            className="grey-icon-v2"
                            src={cardIcon}
                          />
                        </div>
                        <div className="col-10">
                          Credit or Debit Card
                          <p className="small small-grey-m0">
                            Use your Credit or Debit Card
                          </p>
                          <div className="card-brands accordion-right-corner">
                            <PaymentMethodsAccepted />
                          </div>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <PaymentMethodCCForm history={this.props.history} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
            </Accordion>
          </li>
        ),
        order: this.props.store.paymentPage.paymentMethods.order
          ? this.props.store.paymentPage.paymentMethods.order
          : 0,
      },

      {
        elements: (
          <>
            {" "}
            {this.props.store.paymentPage.notes.enabled && (
              <li className="ui-state-default dragable-panel" id="blocknotes">
                <div className="text-center small">
                  {this.props.store.paymentPage.notes.value
                    ? this.props.store.paymentPage.notes.value
                    : ""}
                </div>
              </li>
            )}{" "}
          </>
        ),
        order: this.props.store.paymentPage.notes.order
          ? this.props.store.paymentPage.notes.order
          : 0,
      },
      {
        elements: (
          <>
            {this.props.store.paymentPage.review.enabled && (
              <li className="ui-state-default dragable-panel" id="blockreview">
                <div className="review-total">
                  <h6 className="sub-header-line mb-3">
                    {this.props.store.paymentPage.review.header
                      ? this.props.store.paymentPage.review.header
                      : ""}
                  </h6>
                  <div className="row">
                    <div className="col">Net Amount:</div>
                    <div className="col">
                      $
                      {this.props.store.numberWithCommas(
                        parseFloat(
                          this.props.store.totalAmount["netAmount"]
                        ).toFixed(2)
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">Fee:</div>
                    <div className="col">
                      $
                      {this.props.store.numberWithCommas(
                        parseFloat(this.props.store.totalAmount["fee"]).toFixed(
                          2
                        )
                      )}
                    </div>
                  </div>

                  <div className="row total-amount">
                    <div className="col">Total Amount:</div>
                    <div className="col">
                      $
                      {this.props.store.numberWithCommas(
                        parseFloat(
                          this.props.store.totalAmount["totalAmount"]
                        ).toFixed(2)
                      )}
                    </div>
                  </div>
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.review.order
          ? this.props.store.paymentPage.review.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.messageBeforePaying.enabled && (
              <li
                className="ui-state-default dragable-panel text-center"
                id="blockmessageBeforePaying"
              >
                <p
                  className="small"
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.store.paymentPage.messageBeforePaying
                      .label
                      ? this.props.store.paymentPage.messageBeforePaying.label
                      : "",
                  }}
                ></p>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.messageBeforePaying.order
          ? this.props.store.paymentPage.messageBeforePaying.order
          : 0,
      },

      {
        elements: (
          <li
            className="ui-state-default dragable-panel"
            id="blockpaymentButton"
          >
            <div className="btn-group full-w">
              <button
                style={btnTheme}
                type="button"
                onClick={this.reviewPayment}
                className="btn btn-success btn-lg"
              >
                {this.props.store.paymentPage.paymentButton.label} $
                {this.props.store.numberWithCommas(
                  parseFloat(
                    this.props.store.totalAmount["totalAmount"]
                  ).toFixed(2)
                )}
              </button>
            </div>
          </li>
        ),
        order: this.props.store.paymentPage.paymentButton.order
          ? this.props.store.paymentPage.paymentButton.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.contactUs.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blockcontactUs"
              >
                <p className="small-grey-m0 text-center ">
                  <b>
                    {this.props.store.paymentPage.contactUs.header
                      ? this.props.store.paymentPage.contactUs.header
                      : ""}
                  </b>
                  <br />
                  {this.props.store.paymentPage.contactUs.emailLabel
                    ? this.props.store.paymentPage.contactUs.emailLabel
                    : ""}{" "}
                  |{" "}
                  {this.props.store.paymentPage.contactUs.phoneLabel
                    ? this.props.store.paymentPage.contactUs.phoneLabel
                    : ""}
                </p>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.contactUs.order
          ? this.props.store.paymentPage.contactUs.order
          : 0,
      },
    ];

    return this.sortBlocks(blocksUI);
  }

  render() {
    const bgTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
      height: "50%",
    };

    const linkTheme = {
      color: this.props.store.paymentPageSettings.color,
    };

    const btnTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const subdomain = this.props.store.getPaymentPageSubdomain;
    const entrypoint = this.props.match.params.entry;

    return (
      <div>
        <Modal
          style={{ textAlign: "center" }}
          show={!this.state.infoModalIsOpen}
          onHide={this.closeInfoModal}
          size="sm"
          centered
        >
          <Modal.Body>
            <BiMessageSquareError className="icon-modal" />
            <h5>Info</h5>
            <p className="small">{this.state.infoMessageInModal}</p>
            <Button
              className="btn"
              style={btnTheme}
              onClick={this.closeInfoModal}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.confirmModalIsOpen}
          onHide={() => this.closeConfirmModal()}
          size="md"
          centered
        >
          <Modal.Body className="popover-body">
            <h6 className="sub-header-line mb-3">Payment Information</h6>

            <div className="row mb-3">
              <div className="col-7">
                {this.props.store.getPaymentMethod.toLowerCase() === "card" ||
                this.props.store.getPaymentMethod.toLowerCase() ===
                  "savedcard" ? (
                  this.props.store.getPaymentMethod.toLowerCase() === "card" ? (
                    <>
                      {this.props.store.getPaymethodImg(
                        this.props.store.creditCardType(
                          this.props.store.paymentPage.paymentMethods.cardNumber
                        )
                      )}
                      &nbsp;&nbsp;&nbsp;
                      {this.props.store.paymentPage.paymentMethods.cardNumber
                        ? this.props.store.maskedCardNumber(
                            this.props.store.paymentPage.paymentMethods
                              .cardNumber,
                            "v3"
                          )
                        : ""}
                    </>
                  ) : (
                    <>
                      {this.props.store.getCustomerSelected &&
                      this.props.store.paymentMethodSavedPosition &&
                      this.props.store.getCustomerSelected.StoredMethods &&
                      this.props.store.getCustomerSelected.StoredMethods[
                        this.props.store.paymentMethodSavedPosition
                      ]
                        ? this.props.store.getPaymethodImg(
                            this.props.store.getCustomerSelected.StoredMethods[
                              this.props.store.paymentMethodSavedPosition
                            ].Descriptor
                          )
                        : ""}
                      &nbsp;&nbsp;&nbsp;
                      {this.props.store.getCustomerSelected &&
                      this.props.store.paymentMethodSavedPosition &&
                      this.props.store.getCustomerSelected.StoredMethods &&
                      this.props.store.getCustomerSelected.StoredMethods[
                        this.props.store.paymentMethodSavedPosition
                      ]
                        ? this.props.store.maskedCardNumber(
                            this.props.store.getCustomerSelected.StoredMethods[
                              this.props.store.paymentMethodSavedPosition
                            ].MaskedAccount,
                            "v3"
                          )
                        : ""}
                    </>
                  )
                ) : (
                  <>
                    {this.props.store.getPaymethodImg()} &nbsp;&nbsp;&nbsp; Bank
                    account / E-Check
                  </>
                )}
              </div>
              <div className="col-5 text-right">
                {!this.props.store.isAutopay ? (
                  <small>
                    Paying on{" "}
                    {this.props.store.stringDateFormat(new Date().toString())}
                  </small>
                ) : (
                  <small>
                    Next date{" "}
                    {this.props.store.stringDateFormat(
                      this.props.store.paymentPage.autopay.startDate
                    )}
                  </small>
                )}
              </div>
            </div>

            <div className="review-total">
              <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
              <div className="row">
                <div className="col">Net Amount:</div>
                <div className="col">
                  $
                  {this.props.store.numberWithCommas(
                    parseFloat(
                      this.props.store.totalAmount["netAmount"]
                    ).toFixed(2)
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">Fee:</div>
                <div className="col">
                  $
                  {this.props.store.numberWithCommas(
                    parseFloat(this.props.store.totalAmount["fee"]).toFixed(2)
                  )}
                </div>
              </div>

              <div className="row total-amount">
                <div className="col">Total Amount:</div>
                <div className="col">
                  $
                  {this.props.store.numberWithCommas(
                    parseFloat(
                      this.props.store.totalAmount["totalAmount"]
                    ).toFixed(2)
                  )}
                </div>
              </div>
            </div>

            {this.props.store.getValidationCode() && (
              <>
                <p className="text-title-captcha" >Enter numeric captcha code as seen above without spaces.</p>
                <Captcha subdomain={subdomain} entry={entrypoint} />
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      name="validationCode"
                      value={this.props.store.getValidationCodeInput}
                      placeholder="Enter numeric captcha code"
                      className={
                        this.props.store.getPaymentPageErrors.validationCode
                          ? "form-control input-error"
                          : "form-control"
                      }
                      id="validationCode"
                      autoComplete="off"
                      onChange={(e) =>
                        this.handleTextChangeValidationCode(e.target.value)
                      }
                    />
                    <label
                      htmlFor="validationCode"
                      style={{ padding: "8px .75rem" }}
                    >
                      Enter numeric captcha code
                    </label>
                  </div>
                </div>
              </>
            )}

            <button
              type="button"
              onClick={(e) => this.makePayment()}
              className="btn btn-success btn-lg full-w"
            >
              Pay $
              {this.props.store.numberWithCommas(
                parseFloat(this.props.store.totalAmount["totalAmount"]).toFixed(
                  2
                )
              )}
            </button>
            <div className="text-center mt-3">
              <button
                className="btn btn-default"
                onClick={() => this.closeConfirmModal()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <div className="bg-decorator" style={bgTheme}>
          &nbsp;
        </div>
        <div className="mt-body3">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div id="vterminal-container" className="card">
              <div className="card-body  main-cont">
                <ul className="sorted" ref={this.sortablePayer}>
                  {this.renderSortBlocks().map((block, i) => (
                    <div key={i}>{block.elements}</div>
                  ))}
                </ul>
              </div>

              {this.props.store.paymentPage.contactUs.paymentIcons && (
                <div className="card-brands text-center bottom-grey mt-3">
                  <PaymentMethodsAccepted />
                </div>
              )}
            </div>
            <p className="small mt-5">
              Powered by{" "}
              <a
                rel="noreferrer"
                style={linkTheme}
                target="_blank"
                href="https://www.payabli.com/"
                className="small no-underline"
              >
                Payabli
              </a>
            </p>
          </div>
        </div>
        {this.props.store &&
          this.props.store.paymentPageSettings.customCssUrl && (
            <Helmet>
              <link
                rel="stylesheet"
                href={this.props.store.paymentPageSettings.customCssUrl}
              />
            </Helmet>
          )}
          <ToastContainer transition={Bounce} />
      </div>
    );
  }
}

export { PayLink };
