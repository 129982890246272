/* global ApplePaySession */
import React, { useEffect, useRef } from 'react';
import mainStore from '../store/MainStore';

const ApplePayButton = (...props) => {
  const entrypoint = props[0]?.entrypoint;
  const subdomain = props[0]?.subdomain;
  const executeBefore = props[0]?.executeBefore;
  const callBackFunction = props[0]?.callBackFunction;
  const callBackFunctionError = props[0]?.callBackFunctionError;

  let token = process.env.REACT_APP_TOKEN;
  
  const updateApplePayRequestPayload = (applePayRequestPayload) => {
    let totalAmount = mainStore.totalAmount;

    applePayRequestPayload.total.amount = parseFloat(totalAmount.totalAmount).toFixed(2);
    applePayRequestPayload.supportedNetworks = mainStore.allowedCards;
    applePayRequestPayload.lineItems = [
      {
          label: 'Subtotal',
          amount: parseFloat(totalAmount.netAmount).toFixed(2),
      },
      {
          label: 'Service Fee',
          amount: parseFloat(totalAmount.fee).toFixed(2),
      },
    ];
    return applePayRequestPayload;
  }
  
  const updatePaymentRequest = (paymentRequest) => {
    let totalAmount = mainStore.totalAmount;
    paymentRequest.entryPoint = entrypoint;
    paymentRequest.subdomain = subdomain;
    paymentRequest.paymentDetails.totalAmount = parseFloat(totalAmount.totalAmount).toFixed(2);
    paymentRequest.paymentDetails.serviceFee = parseFloat(totalAmount.fee).toFixed(2);
    paymentRequest.customerData = mainStore.getRequiredPayorFieldsToPay();
    return paymentRequest;
  }

  // ApplePayPaymentRequest
  let applePayRequestPayload = {
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: [],
    lineItems: [],
    total: {
        label: 'Confirm Payment',
        amount: '0.00'
    },
  };

  // PayabliPaymentRequest
  let paymentRequest = {
    entryPoint: '<entrypoint>',
    subdomain: "<subdomain>",
    paymentMethod: {
        method: "wallet",
        walletType: "apple_pay",
        walletToken: "<base 64 string of event.payment received in onpaymentauthorized>"
    },
    paymentDetails: {
        totalAmount: 0.00,
        serviceFee: 0.0
    },
    customerData: '<customerData>'
  };

  const buttonRef = useRef(null);

 

  useEffect(() => {
    if (mainStore.canUseApplePay() && entrypoint && subdomain) {
      const button = buttonRef.current;
      if (button) {
        button.addEventListener('click', () => {

          if(executeBefore && executeBefore() !== true){
            return;
          }

          if (!window.ApplePaySession) {
            return;
          }

          try{
                
              // Create ApplePaySession
              applePayRequestPayload = updateApplePayRequestPayload(applePayRequestPayload);
              const session = new ApplePaySession(3, applePayRequestPayload);
              
              session.onvalidatemerchant = async event => {
                  // Call your own server to request a new merchant session.
                  const response = await fetch(`${process.env.REACT_APP_URL_API}Wallet/applepay/session`, {
                      method: 'POST',
                      body: JSON.stringify({
                          paypointEntrypointId: entrypoint,
                          domainName: window.location.hostname,
                          displayName: "",
                          validationURL: ""
                      }),
                      headers: {
                          'Content-Type': 'application/json',
                          'requestToken': token,
                      },
                  });
              
                  const data = await response.json();
                  session.completeMerchantValidation(data);
              };
              
              // session.onpaymentmethodselected = event => {
              //     // Define ApplePayPaymentMethodUpdate based on the selected payment method.
              //     // No updates or errors are needed, pass an empty object.
              //     const update = {};
              //     session.completePaymentMethodSelection(update);
              // };
              
              // session.onshippingmethodselected = event => {
              //     // Define ApplePayShippingMethodUpdate based on the selected shipping method.
              //     // No updates or errors are needed, pass an empty object. 
              //     const update = {};
              //     session.completeShippingMethodSelection(update);
              // };
              
              // session.onshippingcontactselected = event => {
              //     // Define ApplePayShippingContactUpdate based on the selected shipping contact.
              //     const update = {};
              //     session.completeShippingContactSelection(update);
              // };
              
              session.onpaymentauthorized = async event => {
                  // Define ApplePayPaymentAuthorizationResult
                  let walletToken = base64Encode(JSON.stringify(event.payment));
                  paymentRequest.paymentMethod.walletToken = walletToken;
                  paymentRequest = updatePaymentRequest(paymentRequest);
                  const response = await fetch(`${process.env.REACT_APP_URL_API}MoneyIn/getpaid`, {
                  method: 'POST',
                  body: JSON.stringify(paymentRequest),
                  headers: {
                      'Content-Type': 'application/json',
                      'requestToken': mainStore.getPageToken(),
                  },
                  });
              
                  const data = await response.json();
                  let result;
                  if (data.responseText === "Success") {
                      result = {
                          "status": ApplePaySession.STATUS_SUCCESS
                      };
                  } else {
                      result = {
                          "status": ApplePaySession.STATUS_FAILURE
                      };
                  }

                  if(callBackFunction){
                    callBackFunction(data);
                  }
                  session.completePayment(result);
              };
              
              session.oncancel = event => {
                  // Payment canceled by WebKit
              };
              
              session.begin();

          }catch(e){
            if(callBackFunctionError){
              callBackFunctionError(`Apple Pay, ${e.message}`);
            }
          }

        });
      }
    }
  }, []);

  const base64Encode = (str) => {
    const buffer = new TextEncoder().encode(str);
    const binaryStr = String.fromCharCode.apply(null, buffer);
    return btoa(binaryStr);
  };

  
  return <div className='text-center'><apple-pay-button ref={buttonRef} buttonstyle="black" type="plain" locale="en-US"></apple-pay-button></div> 
  
    
  
};

export default ApplePayButton;