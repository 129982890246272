import "./assets/css/bootstrap.min.css";
import "./assets/css/icheck-bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/apple-pay-button.css";

import React, { Component } from "react";
import { Pay } from "./views/Pay";
import { PayLink } from "./views/PayLink";
import { Receipt } from "./views/Receipt";
import { NotFound } from "./views/NotFound";
import { Layout } from "./components/Layout";
import { Provider } from "mobx-react";
import MainStore from "./store/MainStore";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PaylinkPaid } from "./views/PaylinkPaid";
import { VendorPayLink } from "./views/VendorPayLink";
import { VendorPayLinkReceipt } from "./views/VendorPayLinkReceipt";
import BillStore from "./store/BillStore";
import { VendorPayLinkPayabli } from "./views/VendorPayLinkPayabli";
import { VendorPayLinkPayabli2 } from "./views/VendorPayLinkPayabli2";
import { VendorPayLinkGeneric } from "./views/VendorPayLinkGeneric";

class App extends Component {
  componentDidMount() {}
  render() {
    return (
      <Provider store={MainStore} billStore={BillStore}>
        <Layout>
          <Router>
            <Switch>
              <Route exact path="/paymentlink/paid" component={PaylinkPaid} />
              <Route
                exact
                path="/paymentlink/receipt/:tokenid"
                component={Receipt}
              />
              <Route
                exact
                path="/paymentlink/:entry/:tokenid"
                component={PayLink}
              />
              <Route
                exact
                path="/vendorpaymentlink/:entry/:tokenid"
                component={VendorPayLink}
              />
              <Route
                exact
                path="/vendorpaymentlink/:entry/:tokenid/receipt/:data"
                component={VendorPayLinkReceipt}
              />
              <Route
                exact
                path="/vendorpaymentlink"
                component={VendorPayLink}
              />
              <Route
                exact
                path="/vendorpaymentlink/payabli"
                component={VendorPayLinkPayabli}
              />
               <Route
                exact
                path="/vendorpaymentlink/payabli2"
                component={VendorPayLinkPayabli2}
              />
               <Route
                exact
                path="/vendorpaymentlink/generic"
                component={VendorPayLinkGeneric}
              />
              <Route exact path="/:entry/:subdomain?" component={Pay} />
              <Route
                exact
                path="/:entry/:subdomain/json/:datainjectedjson?"
                component={Pay}
              />
              <Route
                exact
                path="/receipt/:entry/:subdomain"
                component={Receipt}
              />
              <Route
                exact
                path="/receipt/:entry/:subdomain/:datainjectedjson?"
                component={Receipt}
              />
              <Route exact path="/" component={NotFound} />
              <Route path="*" exact component={NotFound} />
            </Switch>
          </Router>
        </Layout>
      </Provider>
    );
  }
}

export default App;
