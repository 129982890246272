import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiCheckCircle } from 'react-icons/bi';

@inject('store')
@observer
class PaylinkPaid extends React.Component {

    prevent(e) {
        e.preventDefault();
    }    

    componentDidMount() {
       
    }

    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        return (
            <div>
            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
            <div className="mt-body3">
            <div className="d-flex flex-column sjustify-content-center align-items-center">
                <div className="card" style={{width: '50em'}}>
                    <div className="card-body text-center" style={{padding: "40px"}}>
                        <BiCheckCircle className="mb-4 green-text" style={{fontSize: "60px"}}/>
                        <h5>Payment link Paid</h5>
                        <p className="small">
                        This Paylink will not be available anymore,<br/> this invoice has already been paid.
                        </p>
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export { PaylinkPaid };