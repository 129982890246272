import React from 'react';
import { inject, observer } from 'mobx-react';
import { FaCheckCircle } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import {Helmet} from "react-helmet";
import { PaymentPageLogo } from '../components/PaymentPageLogo';
import ConfettiExplosion from 'react-confetti-explosion';

@inject('store', 'billStore')
@observer
class VendorPayLinkReceipt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfetti: false,
            data: {}
        };
    }

    getAmount(){
        return this.state.data.netAmount ? '$'+this.props.store.numberWithCommas(parseFloat(this.state.data.netAmount).toFixed(2)) : '0.00';
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.store.setLoading(false);

        let data = this.props.match.params.data;
        if(data){
            this.setState({
                data: JSON.parse(window.atob(decodeURIComponent(data)))
            })
        }

        let thisObj = this;
        setTimeout(function(){
            thisObj.setState({showConfetti : true})
        }, 900)
    }


    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color ? this.props.store.paymentPageSettings.color: "#10A0E3",
            height: "50%"
        };

        const linkTheme = {
            color: this.props.store.paymentPageSettings.color ? this.props.store.paymentPageSettings.color : "#10A0E3",
        };

        /* const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
            border: "none",
        }; */

       return (
           <div>

        <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
           <div className="mt-body3">

           <div className="d-flex flex-column justify-content-center align-items-center">

               <div id="vterminal-container" className="card pb-4">
               <div className="card-body  main-cont">
                    <div className="text-center">
                        <PaymentPageLogo payabliDefaultLogo={false}/>
                    </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2 progress-steps mb-3">
                            <div className="row">
                                <div className="col active">
                                    &nbsp;
                                </div>
                                <div className="col active">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="card-title text-center">Your pay is on the way</h3>
                    <div className='confetti'>
                        {this.state.showConfetti &&
                        <ConfettiExplosion colors={['#FFC700','#FF0000','#2E3191','#41BBC7']}/>
                        }
                    </div>
                    <p className="small small-grey text-center m0">For the amount of</p>
                    <div className='big-amount mt-3 mb-4'>{this.getAmount()}</div>
                    
                    <div className="row mb-5 small">
                        <div class="offset-sm-2 col-sm-4 mb-2 text-center">
                            <b>Invoice Number</b>
                            <br/>
                            {this.state.data?.invoiceNumber}
                        </div>
                        <div class="col-sm-4 mb-2 text-center">
                            <b>Payment Method</b>
                            <br/>
                            {this.state.data?.paymentMethod? this.props.store.getPaymentMethodName(this.state.data.paymentMethod) : ''}
                        </div>
                    </div>


                    <p className='text-center small mb-5'>Thanks! You will receive your payment of {this.getAmount()} to your {this.state.data?.paymentMethod? this.props.store.getPaymentMethodName(this.state.data.paymentMethod) : ''}.<br/>
                        The funds will be available as soon as possible. We will notify you when the funds are available.
                    </p>

                    <div className='receipt-timeline'>
                        <div>
                            <FaCheckCircle className='active'/>
                        </div>
                        <div className='line'>&nbsp;</div>
                        <div><FaCheckCircle/></div>
                        <div className='line'>&nbsp;</div>
                        <div><FaCheckCircle/></div>
                    </div>
                    <div className='receipt-timeline-labels mb-5'>
                        <div>
                            <span>Generating your payment</span>
                        </div>
                        <div>&nbsp;</div>
                        <div>
                            <span> Payment Created</span>
                        </div>
                        <div>&nbsp;</div>
                        <div>
                            <span>Paid</span>
                        </div>
                    </div>
               </div>
               </div>
               <p className="small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>

           </div>

           </div>
           {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
           </div>
       );
   }
}

export { VendorPayLinkReceipt };